import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FileDropInput} from '../atoms/fileDropInput'
import {Form, Input} from 'rsuite'
import {generateID} from '../utility'
import {TenantSslCertificateFe} from './tenantEditPanel'

export interface CertEditPanelProps {
  value: TenantSslCertificateFe
  // TODO: type
  onChange(cert: any): void
}

export function CertEditPanel({value, onChange}: CertEditPanelProps) {
  const [error, setError] = useState(false)

  async function handleDrop(files: File[]) {
    if (files.length === 0) return
    const file = files[0]

    const result = new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = async function () {
        if (typeof reader.result === 'string') {
          const base64Result = reader.result.substring(reader.result.indexOf('base64,') + 7)
          const decodedString = atob(base64Result)
          resolve(decodedString)
        }
      }

      reader.onerror = function (error) {
        console.log('Error: ', error)
        setError(true)
        reject(error)
      }
    })

    return result
  }

  const {t} = useTranslation()

  return (
    <>
      <Form.Group>
        <Form.Control
          name={t('tenantList.panels.name')}
          placeholder={t('tenantList.panels.name')}
          value={value.name && value.name.split('-wpid-')[0]}
          disabled={value.id !== ''}
          onChange={(name) => {
            onChange((value: TenantSslCertificateFe) => ({
              ...value,
              name: `${name}-wpid-${generateID()}`,
            }))
          }}
        />
      </Form.Group>

      <Form.ControlLabel>{t('tenantList.panels.publicCert')}</Form.ControlLabel>

      {!value.publicKey || value.publicKey === '' ? (
        <div style={{height: '200px'}}>
          <FileDropInput
            disabled={false}
            text={t('tenantList.panels.dragDropPublicCert')}
            onDrop={async (file) => {
              const cert = await handleDrop(file)
              onChange((value: TenantSslCertificateFe) => ({...value, publicKey: cert}))
            }}
          />
        </div>
      ) : (
        <Input
          as="textarea"
          rows={3}
          style={{width: '100%', height: '200px'}}
          placeholder={t('blocks.embeds.panels.embed')}
          value={value.publicKey}
          onChange={(input) => {
            onChange((value: TenantSslCertificateFe) => ({...value, publicKey: input}))
          }}
        />
      )}

      <br />

      <Form.ControlLabel>{t('tenantList.panels.privateCert')}</Form.ControlLabel>

      {!value.privateKey || value.privateKey === '' ? (
        <div style={{height: '200px'}}>
          <FileDropInput
            disabled={false}
            text={t('tenantList.panels.dragDropPrivateCert')}
            onDrop={async (file) => {
              const cert = await handleDrop(file)
              onChange((value: TenantSslCertificateFe) => ({...value, privateKey: cert}))
            }}
          />
        </div>
      ) : (
        <Input
          as="textarea"
          rows={3}
          style={{width: '100%', height: '200px'}}
          placeholder={t('blocks.embeds.panels.embed')}
          value={value.privateKey}
          onChange={(input) => {
            onChange((value: TenantSslCertificateFe) => ({...value, privateKey: input}))
          }}
        />
      )}
    </>
  )
}
