import React, {useEffect, useState} from 'react'
import {ButtonLink, Link, TenantCreateRoute, TenantEditRoute} from '../route'
import {useTranslation} from 'react-i18next'
import {FullTenantFragment, TenantSort, useDeleteTenantMutation, useTenantListQuery} from '../api'
import {
  Button,
  FlexboxGrid,
  Input,
  InputGroup,
  Table,
  Popover,
  Whisper,
  toaster,
  Message,
  Pagination,
} from 'rsuite'
import {
  DEFAULT_TABLE_PAGE_SIZES,
  formatDate,
  mapTableSortTypeToGraphQLSortOrder,
  postData,
} from '../utility'
import {parseBoolean} from '../../shared/utility'

import Search from '@rsuite/icons/legacy/Search'
import {OverlayLoader} from '../atoms/loader'

const {Column, HeaderCell, Cell} = Table

function mapColumFieldToGraphQLField(columnField: string): TenantSort | null {
  switch (columnField) {
    case 'createdAt':
      return TenantSort.CreatedAt
    case 'modifiedAt':
      return TenantSort.ModifiedAt
    default:
      return null
  }
}

export function TenantList() {
  const [filter, setFilter] = useState('')

  const [currentTenant, setCurrentTenant] = useState<FullTenantFragment>()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sortField, setSortField] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [tenants, setTenants] = useState<FullTenantFragment[]>([])

  const [loading, setLoading] = useState(false)

  const {t} = useTranslation()

  const {
    data,
    refetch,
    loading: isLoading,
  } = useTenantListQuery({
    variables: {
      filter: filter || undefined,
      first: limit,
      skip: page - 1,
      sort: mapColumFieldToGraphQLField(sortField),
      order: mapTableSortTypeToGraphQLSortOrder(sortOrder),
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    refetch({
      filter: filter || undefined,
      first: limit,
      skip: page - 1,
      sort: mapColumFieldToGraphQLField(sortField),
      order: mapTableSortTypeToGraphQLSortOrder(sortOrder),
    })
  }, [filter, page, limit, sortOrder, sortField, refetch])

  useEffect(() => {
    document.title = t('tenantList.overview.tenants')
  })

  const [deleteTenant, {loading: isDeleting}] = useDeleteTenantMutation()

  const rowDeleteButton = (rowData: any) => {
    const triggerRef = React.createRef<any>()
    const close = () => triggerRef.current.close()
    const speaker = (
      <Popover title={currentTenant?.name}>
        <Button
          color="red"
          disabled={isDeleting}
          onClick={async () => {
            setLoading(true)

            if (!currentTenant) return

            close()

            const res = await postData(
              '/kubectl-delete-all',
              JSON.stringify({tenant: currentTenant})
            )

            if (res.response.status === 200) {
              if (
                typeof process !== 'undefined' &&
                process.env.DEBUG_MODE &&
                parseBoolean(process.env.DEBUG_MODE)
              ) {
                console.log(
                  '!!! DEBUG MODE is turned on, the ingress is only deleted on GCP but remains in the database. !!!'
                )

                toaster.push(
                  <Message closable type="success" duration={2000}>
                    {t('global.success.delete')}
                  </Message>
                )
              } else {
                deleteTenant({
                  variables: {id: currentTenant.id},
                })
                  .then(() => {
                    refetch()
                  })
                  .catch(console.error)

                setLoading(false)

                toaster.push(
                  <Message closable type="success" duration={2000}>
                    {t('global.success.delete')}
                  </Message>
                )
              }
            } else {
              setLoading(false)
              toaster.push(
                <Message closable type="error" duration={0}>
                  {t('global.error.delete')}
                </Message>
              )
            }
          }}
        >
          {t('global.buttons.deleteNow')}
        </Button>
      </Popover>
    )
    return (
      <>
        <Whisper placement="left" trigger="click" speaker={speaker} ref={triggerRef}>
          <Button
            style={{padding: '0'}}
            appearance="link"
            color="red"
            onClick={() => {
              setCurrentTenant(rowData)
            }}
          >
            {t('global.buttons.delete')}{' '}
          </Button>
        </Whisper>
      </>
    )
  }

  useEffect(() => {
    if (data?.tenants?.nodes) {
      setTenants(data.tenants.nodes)
      if (data.tenants.totalCount + 9 < page * limit) {
        setPage(1)
      }
    }
  }, [data, limit, page])

  return (
    <>
      {loading && <OverlayLoader />}

      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <h2>{t('tenantList.overview.tenants')}</h2>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={8} style={{textAlign: 'right'}}>
          <ButtonLink
            appearance="primary"
            disabled={isLoading}
            route={TenantCreateRoute.create({})}
          >
            {t('tenantList.overview.newTenant')}
          </ButtonLink>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={24} style={{marginTop: '20px'}}>
          <InputGroup>
            <InputGroup.Addon>
              <Search />
            </InputGroup.Addon>
            <Input value={filter} onChange={(value) => setFilter(value)} />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Table
        style={{marginTop: '20px', overflowY: 'auto'}}
        height={510}
        autoHeight={true}
        loading={isLoading}
        data={tenants}
        sortColumn={sortField}
        sortType={sortOrder}
        onSortColumn={(sortColumn, sortType) => {
          if (sortType) setSortOrder(sortType)
          if (sortColumn) setSortField(sortColumn)
        }}
      >
        <Column flexGrow={2} align="left" sortable>
          <HeaderCell>{t('tenantList.overview.name')}</HeaderCell>
          <Cell dataKey={'name'}>
            {(rowData: FullTenantFragment) => (
              <Link route={TenantEditRoute.create({id: rowData.id})}>
                {rowData.name || t('tenantList.overview.unknown')}
              </Link>
            )}
          </Cell>
        </Column>

        <Column flexGrow={1} minWidth={140} align="left" sortable>
          <HeaderCell>{t('userList.overview.createdAt')}</HeaderCell>
          <Cell dataKey="createdAt">
            {(rowData: FullTenantFragment) => formatDate(rowData.createdAt)}
          </Cell>
        </Column>

        <Column flexGrow={1} minWidth={140} align="left" sortable>
          <HeaderCell>{t('userList.overview.modifiedAt')}</HeaderCell>
          <Cell dataKey="modifiedAt">
            {(rowData: FullTenantFragment) => formatDate(rowData.modifiedAt)}
          </Cell>
        </Column>

        <Column width={140} align="right" fixed="right">
          <HeaderCell>{t('global.buttons.action')}</HeaderCell>
          <Cell>{(rowData: FullTenantFragment) => <>{rowDeleteButton(rowData)}</>}</Cell>
        </Column>
      </Table>

      <Pagination
        style={{padding: '20px 0', display: 'flex', justifyContent: 'flex-end'}}
        layout={['limit', '|', 'total', '|', 'pager']}
        size="xs"
        prev
        next
        first
        last
        ellipsis
        total={data?.tenants.totalCount || 0}
        limit={limit}
        limitOptions={DEFAULT_TABLE_PAGE_SIZES}
        maxButtons={5}
        activePage={page}
        onChangePage={(page: React.SetStateAction<number>) => {
          setPage(page)
        }}
        onChangeLimit={(limit: number) => {
          setLimit(limit)
        }}
      />
    </>
  )
}
