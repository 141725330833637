import React, {useState} from 'react'
import {Button, Form, Notification, toaster} from 'rsuite'
import {useResetUserPasswordMutation} from '../api'
import {useTranslation} from 'react-i18next'
import passwordValidator from 'password-validator'

export interface ResetUserPasswordPanelProps {
  userID?: string
  userName?: string
  onClose(): void
}

export function ResetUserPasswordPanel({userID, userName, onClose}: ResetUserPasswordPanelProps) {
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState<boolean | any[]>()
  const [passwordErrors, setPasswordErrors] = useState<boolean | any[]>()

  async function validatePassword() {
    // eslint-disable-next-line new-cap
    const schema = new passwordValidator()

    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits(1) // Must have at least 2 digits
      .has()
      .symbols(1)
      .has()
      .not()
      .spaces() // Should not have spaces

    return schema.validate(password, {list: true})
  }

  const [resetUserPassword, {loading: isUpdating, error: updateError}] =
    useResetUserPasswordMutation()

  const isDisabled = isUpdating

  const {t} = useTranslation()

  return (
    <>
      <Form fluid={true}>
        <Form.Group>
          <Form.ControlLabel>{t('userList.panels.resetPasswordFor', {userName})}</Form.ControlLabel>
          <Form.Control
            name="resetPasswordFor"
            disabled={isDisabled}
            type="password"
            placeholder={t('userList.panels.password')}
            errorMessage={updateError?.message}
            value={password}
            onChange={(value) => setPassword(value)}
          />
        </Form.Group>
      </Form>

      {Array.isArray(passwordErrors) && passwordErrors.length !== 0 && (
        <>
          <strong>{t('userList.panels.pw.passwordRequirements')}</strong>
          {passwordErrors.map((item) => (
            <>
              <div>{t(`userList.panels.pw.${item}`)}</div>
            </>
          ))}
          <br />
        </>
      )}

      <Button
        disabled={isDisabled}
        appearance="primary"
        onClick={async () => {
          if (!userID || !password) {
            return
          }

          const res = await validatePassword()

          setPasswordErrors(res)
          setIsValid(Array.isArray(res) && res.length !== 0)

          if (isValid) {
            const {data} = await resetUserPassword({
              variables: {
                id: userID,
                password: password,
              },
            })
            if (data?.resetUserPassword) {
              toaster.push(
                <Notification
                  closable
                  type="success"
                  header={t('userList.panels.passwordChangeSuccess')}
                  duration={2000}
                ></Notification>,
                {placement: 'topEnd'}
              )
              onClose()
            }
          }
        }}
      >
        {t('userList.panels.resetPassword')}
      </Button>
    </>
  )
}
