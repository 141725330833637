import {initClient} from './client'

import './global.less'

if (document.readyState !== 'loading') {
  initClient()
} else {
  document.addEventListener('DOMContentLoaded', () => {
    initClient()
  })
}
