import React from 'react'
import {Loader} from 'rsuite'

export function OverlayLoader() {
  return (
    <div
      className="overlay"
      style={{
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: 'rgba(0,0,0,0.1)',
        zIndex: 99999,
      }}
    >
      <div
        className="overlay__inner"
        style={{left: '0', top: '0', width: '100%', height: '100%', position: 'absolute'}}
      >
        <div
          className="overlay__content"
          style={{
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader
            size="md"
            style={{display: 'flex', justifyContent: 'center', position: 'relative'}}
            content="Loading..."
          />
        </div>
      </div>
    </div>
  )
}
