import React, {useState, useEffect} from 'react'
import {
  RouteType,
  useRoute,
  useRouteDispatch,
  UserRoleCreateRoute,
  UserRoleListRoute,
  ButtonLink,
  UserRoleEditRoute,
  Link,
} from '../route'

import {useDeleteUserRoleMutation, useUserRoleListQuery, FullUserRoleFragment} from '../api'
import {UserRoleEditPanel} from '../panel/userRoleEditPanel'

import {useTranslation} from 'react-i18next'
import {FlexboxGrid, Input, InputGroup, Table, Modal, Button, Popover, Whisper} from 'rsuite'
import {RouteActionType} from '../router'

import Search from '@rsuite/icons/legacy/Search'

const {Column, HeaderCell, Cell} = Table

export function UserRoleList() {
  const {t} = useTranslation()

  const {current} = useRoute()
  const dispatch = useRouteDispatch()

  const [isEditModalOpen, setEditModalOpen] = useState(
    current?.type === RouteType.UserRoleEdit || current?.type === RouteType.UserRoleCreate
  )

  const [editID, setEditID] = useState<string | undefined>(
    current?.type === RouteType.UserRoleEdit ? current.params.id : undefined
  )

  const [filter, setFilter] = useState('')

  const [userRoles, setUserRoles] = useState<FullUserRoleFragment[]>([])
  const [currentUserRole, setCurrentUserRole] = useState<FullUserRoleFragment>()

  const {
    data,
    refetch,
    loading: isLoading,
  } = useUserRoleListQuery({
    variables: {
      filter: filter || undefined,
      first: 200,
    },
    fetchPolicy: 'network-only',
  })

  const [deleteUserRole, {loading: isDeleting}] = useDeleteUserRoleMutation()

  const rowDeleteButton = (rowData: any) => {
    const triggerRef = React.createRef<any>()
    const close = () => triggerRef.current.close()
    const speaker = (
      <Popover title={currentUserRole?.name}>
        <Button
          color="red"
          disabled={isDeleting}
          onClick={() => {
            if (!currentUserRole) return
            close()
            deleteUserRole({
              variables: {id: currentUserRole.id},
            })
              .then(() => {
                refetch()
              })
              .catch(console.error)
          }}
        >
          {t('global.buttons.deleteNow')}
        </Button>
      </Popover>
    )
    return (
      <>
        <Whisper placement="left" trigger="click" speaker={speaker} ref={triggerRef}>
          <Button
            style={{padding: '0'}}
            appearance="link"
            color="red"
            onClick={() => {
              setCurrentUserRole(rowData)
            }}
          >
            {t('global.buttons.delete')}{' '}
          </Button>
        </Whisper>
      </>
    )
  }

  useEffect(() => {
    if (current?.type === RouteType.UserRoleCreate) {
      setEditID(undefined)
      setEditModalOpen(true)
    }

    if (current?.type === RouteType.UserRoleEdit) {
      setEditID(current.params.id)
      setEditModalOpen(true)
    }
  }, [current])

  useEffect(() => {
    if (data?.userRoles?.nodes) {
      setUserRoles(data.userRoles.nodes)
    }
  }, [data?.userRoles])

  useEffect(() => {
    document.title = t('userRoles.overview.userRoles')
  })

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <h2>{t('userRoles.overview.userRoles')}</h2>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} style={{textAlign: 'right'}}>
          <ButtonLink
            appearance="primary"
            disabled={isLoading}
            route={UserRoleCreateRoute.create({})}
          >
            {t('userRoles.overview.newUserRole')}
          </ButtonLink>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} style={{marginTop: '20px'}}>
          <InputGroup>
            <InputGroup.Addon>
              <Search />
            </InputGroup.Addon>
            <Input value={filter} onChange={(value) => setFilter(value)} />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Table autoHeight={true} style={{marginTop: '20px'}} loading={isLoading} data={userRoles}>
        <Column flexGrow={1} align="left">
          <HeaderCell>{t('userRoles.overview.name')}</HeaderCell>
          <Cell>
            {(rowData: FullUserRoleFragment) => (
              <Link route={UserRoleEditRoute.create({id: rowData.id})}>
                {rowData.name || t('userRoles.overview.untitled')}
              </Link>
            )}
          </Cell>
        </Column>
        <Column flexGrow={2} align="left">
          <HeaderCell>{t('userRoles.overview.description')}</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column align="right" fixed="right">
          <HeaderCell>{t('global.buttons.action')}</HeaderCell>
          <Cell>{(rowData: FullUserRoleFragment) => <>{rowDeleteButton(rowData)}</>}</Cell>
        </Column>
      </Table>

      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setEditModalOpen(false)
          dispatch({
            type: RouteActionType.PushRoute,
            route: UserRoleListRoute.create({}, current ?? undefined),
          })
        }}
        size={'sm'}
      >
        <UserRoleEditPanel
          id={editID!}
          onClose={() => {
            setEditModalOpen(false)
            dispatch({
              type: RouteActionType.PushRoute,
              route: UserRoleListRoute.create({}, current ?? undefined),
            })
          }}
          onSave={() => {
            setEditModalOpen(false)
            refetch()
            dispatch({
              type: RouteActionType.PushRoute,
              route: UserRoleListRoute.create({}, current ?? undefined),
            })
          }}
        />
      </Modal>
    </>
  )
}
