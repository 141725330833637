import React, {SyntheticEvent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Notification, Button, Form, SelectPicker, toaster, Loader} from 'rsuite'
import {postData} from '../utility'

import {ItemDataType} from 'rsuite/esm/@types/common'
import {OverlayLoader} from '../atoms/loader'

export function CopyData() {
  const {t} = useTranslation()

  const [dbs, setDbs] = useState<{destinationDBs: string[]; sourceDBs: string[]} | null>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitLoading, setSubmitIsLoading] = useState(false)
  const [sourceDB, setSourceDB] = useState<string | null>(null)
  const [destinationDB, setDestinationDB] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postData('/getDBs', JSON.stringify({test: '123'}))

        setDbs(await JSON.parse(response.data).data)
      } catch (error) {
        console.error('Error post data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  async function onSubmit() {
    try {
      setSubmitIsLoading(true)

      await postData('/copyData', JSON.stringify({fromDB: sourceDB, toDB: destinationDB}))

      toaster.push(
        <Notification
          closable
          type="success"
          header="DB & Media copied successfully"
          duration={5000}
        ></Notification>,
        {placement: 'topEnd'}
      )
    } catch (error) {
      console.log(error)

      toaster.push(<Notification closable type="error" header={error.toString()}></Notification>, {
        placement: 'topEnd',
      })
    } finally {
      setSubmitIsLoading(false)
    }
  }

  function cleanDbLabel(db: string) {
    const lastIndex = db.lastIndexOf('_')

    if (lastIndex !== -1) {
      const result = db.substring(0, lastIndex)
      return result
    } else {
      return db
    }
  }

  let sourceDBs: ItemDataType<string>[] = []
  if (dbs && dbs?.sourceDBs) {
    sourceDBs = dbs.sourceDBs
      .sort()
      .map((item) => ({label: cleanDbLabel(item), value: item})) as ItemDataType<string>[]
  }

  let destinationDBs: ItemDataType<string>[] = []
  if (dbs && dbs?.destinationDBs) {
    destinationDBs = dbs.destinationDBs
      .sort()
      .map((item) => ({label: cleanDbLabel(item), value: item})) as ItemDataType<string>[]
  }

  function selectPicker(
    onChange: (value: string | null, event: SyntheticEvent<Element, Event>) => void,
    data: ItemDataType<string>[]
  ) {
    if (isLoading) {
      return (
        <Loader
          size="sm"
          style={{display: 'flex', justifyContent: 'flex-start', position: 'relative'}}
        />
      )
    }

    if (!data) return <div>{t('copyData.errorLoadingDB')}</div>

    return (
      <SelectPicker
        data={data}
        style={{width: 224}}
        onChange={(db, event) => {
          onChange(db || null, event)
        }}
      />
    )
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {isSubmitLoading && <OverlayLoader />}
      <h2>{t('navbar.copyData')}</h2>
      <Form>
        <Form.Group>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
              <h6 className="wep-section-title">{t('copyData.source')}</h6>
              {selectPicker(setSourceDB, sourceDBs)}
            </div>
            <br />
            <div>
              <h6 className="wep-section-title">{t('copyData.destination')}</h6>
              {selectPicker(setDestinationDB, destinationDBs)}
            </div>
            <br />
            <div className="wep-section-title">
              <Button
                disabled={isLoading || !sourceDB || !destinationDB}
                onClick={() => {
                  onSubmit().catch((e) => {
                    console.error(e)
                  })
                }}
              >
                {t('copyData.copyDataToStaging')}
              </Button>
            </div>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}
