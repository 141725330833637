export function slugifyString(str: string, replaceDash = true) {
  let res = str
    .toLowerCase()
    .trim()
    .replace(/\s/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')

  if (replaceDash) res = res.replace(/^-+|-+$/g, '')

  return res
}

export function parseBoolean(value: string) {
  if (value === 'true') return true

  return false
}
