// THIS FILE IS AUTOGENERATED, EDIT WITH CAUTION
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Config = {
  __typename?: 'Config';
  languages: LanguagesConfig;
};

export type GraphQlIngressRulesInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LanguageConfig = {
  __typename?: 'LanguageConfig';
  description: Scalars['String'];
  tag: Scalars['String'];
};

export type LanguagesConfig = {
  __typename?: 'LanguagesConfig';
  defaultLanguageTag: Scalars['String'];
  languages: Array<LanguageConfig>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createSession: SessionWithToken;
  createSessionWithJWT: SessionWithToken;
  createTenant?: Maybe<Tenant>;
  createUser?: Maybe<User>;
  createUserRole?: Maybe<UserRole>;
  deleteTenant?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  deleteUserRole?: Maybe<Scalars['String']>;
  resetUserPassword?: Maybe<User>;
  revokeActiveSession: Scalars['Boolean'];
  revokeSession: Scalars['Boolean'];
  sendJWTLogin: Scalars['String'];
  sessions: Array<Session>;
  updateTenant?: Maybe<Tenant>;
  updateUser?: Maybe<User>;
  updateUserRole?: Maybe<UserRole>;
};


export type MutationCreateSessionArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateSessionWithJwtArgs = {
  jwt: Scalars['String'];
};


export type MutationCreateTenantArgs = {
  input: TenantInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
  password: Scalars['String'];
};


export type MutationCreateUserRoleArgs = {
  input: UserRoleInput;
};


export type MutationDeleteTenantArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['ID'];
};


export type MutationResetUserPasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
  sendMail?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRevokeSessionArgs = {
  id: Scalars['ID'];
};


export type MutationSendJwtLoginArgs = {
  email: Scalars['String'];
  url: Scalars['String'];
};


export type MutationUpdateTenantArgs = {
  id: Scalars['ID'];
  input: TenantInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID'];
  input: UserRoleInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  deprecated: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['String'];
};

export type Properties = {
  __typename?: 'Properties';
  key: Scalars['String'];
  public: Scalars['Boolean'];
  value: Scalars['String'];
};

export type PropertiesInput = {
  key: Scalars['String'];
  public: Scalars['Boolean'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  config: Config;
  me?: Maybe<User>;
  permissions?: Maybe<Array<Permission>>;
  sessions: Array<Session>;
  tenant?: Maybe<Tenant>;
  tenants: TenantConnection;
  user?: Maybe<User>;
  userRole?: Maybe<UserRole>;
  userRoles: UserRoleConnection;
  users: UserConnection;
};


export type QueryTenantArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTenantsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TenantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<TenantSort>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  sort?: InputMaybe<UserRoleSort>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserSort>;
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['Float'];
  expiresAt: Scalars['Float'];
  id: Scalars['ID'];
  user: User;
};

export type SessionWithToken = {
  __typename?: 'SessionWithToken';
  createdAt: Scalars['Float'];
  expiresAt: Scalars['Float'];
  id: Scalars['ID'];
  token: Scalars['String'];
  user: User;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type Tenant = {
  __typename?: 'Tenant';
  active: Scalars['Boolean'];
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  modifiedAt: Scalars['Float'];
  name: Scalars['String'];
  services: TenantServices;
};

export type TenantBasicAuth = {
  __typename?: 'TenantBasicAuth';
  active: Scalars['Boolean'];
  pass?: Maybe<Scalars['String']>;
  secretName: Scalars['String'];
  user?: Maybe<Scalars['String']>;
};

export type TenantBasicAuthInput = {
  active: Scalars['Boolean'];
  pass?: InputMaybe<Scalars['String']>;
  secretName: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type TenantConnection = {
  __typename?: 'TenantConnection';
  nodes: Array<Tenant>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TenantFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TenantIngressRules = {
  __typename?: 'TenantIngressRules';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type TenantInput = {
  active: Scalars['Boolean'];
  name: Scalars['String'];
  services: TenantServicesInput;
};

export type TenantMediaProxy = {
  __typename?: 'TenantMediaProxy';
  index: Scalars['String'];
  noIndex: Scalars['String'];
};

export type TenantMediaProxyInput = {
  index: Scalars['String'];
  noIndex: Scalars['String'];
};

export type TenantServices = {
  __typename?: 'TenantServices';
  api: TenantServicesApi;
  certificates: Array<Maybe<TenantSslCertificate>>;
  editor: TenantServicesEditor;
  frontends: TenantServicesFrontend;
  media: TenantServicesMedia;
  mediaProxy: TenantMediaProxy;
  service: Scalars['String'];
};

export type TenantServicesApi = {
  __typename?: 'TenantServicesApi';
  domainList: Array<Maybe<TenantServicesDomainList>>;
};

export type TenantServicesApiInput = {
  domainList?: InputMaybe<Array<InputMaybe<TenantServicesFrontendDomainListInput>>>;
};

export type TenantServicesDomainList = {
  __typename?: 'TenantServicesDomainList';
  basicAuth?: Maybe<TenantBasicAuth>;
  certificate: Scalars['String'];
  domain: Scalars['String'];
  id: Scalars['String'];
  ingressName: Scalars['String'];
  ingressRules?: Maybe<Array<Maybe<TenantIngressRules>>>;
  mediaProxy?: Maybe<Array<Maybe<Scalars['String']>>>;
  protocol: Scalars['String'];
};

export type TenantServicesEditor = {
  __typename?: 'TenantServicesEditor';
  domainList: Array<Maybe<TenantServicesDomainList>>;
};

export type TenantServicesEditorInput = {
  domainList?: InputMaybe<Array<InputMaybe<TenantServicesFrontendDomainListInput>>>;
};

export type TenantServicesFrontend = {
  __typename?: 'TenantServicesFrontend';
  domainList: Array<Maybe<TenantServicesDomainList>>;
};

export type TenantServicesFrontendDomainListInput = {
  basicAuth?: InputMaybe<TenantBasicAuthInput>;
  certificate: Scalars['String'];
  domain: Scalars['String'];
  id: Scalars['String'];
  ingressName: Scalars['String'];
  ingressRules?: InputMaybe<Array<InputMaybe<GraphQlIngressRulesInput>>>;
  mediaProxy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  protocol: Scalars['String'];
};

export type TenantServicesFrontendInput = {
  domainList?: InputMaybe<Array<InputMaybe<TenantServicesFrontendDomainListInput>>>;
};

export type TenantServicesInput = {
  api: TenantServicesApiInput;
  certificates: Array<InputMaybe<TenantSslCertificateInput>>;
  editor: TenantServicesEditorInput;
  frontends: TenantServicesFrontendInput;
  media: TenantServicesMediaInput;
  mediaProxy: TenantMediaProxyInput;
  service: Scalars['String'];
};

export type TenantServicesMedia = {
  __typename?: 'TenantServicesMedia';
  domainList: Array<Maybe<TenantServicesDomainList>>;
};

export type TenantServicesMediaInput = {
  domainList?: InputMaybe<Array<InputMaybe<TenantServicesFrontendDomainListInput>>>;
};

export enum TenantSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export type TenantSslCertificate = {
  __typename?: 'TenantSslCertificate';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TenantSslCertificateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  address?: Maybe<UserAddress>;
  createdAt: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  lastLogin?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['Float'];
  name: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  properties: Array<Properties>;
  roles: Array<UserRole>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  streetAddress: Scalars['String'];
  streetAddress2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type UserAddressInput = {
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  streetAddress: Scalars['String'];
  streetAddress2?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserFilter = {
  name?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  active: Scalars['Boolean'];
  address?: InputMaybe<UserAddressInput>;
  email: Scalars['String'];
  name: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  properties: Array<PropertiesInput>;
  roleIDs?: InputMaybe<Array<Scalars['String']>>;
};

export type UserRole = {
  __typename?: 'UserRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  systemRole: Scalars['Boolean'];
};

export type UserRoleConnection = {
  __typename?: 'UserRoleConnection';
  nodes: Array<UserRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserRoleFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type UserRoleInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  permissionIDs?: InputMaybe<Array<Scalars['String']>>;
};

export enum UserRoleSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export enum UserSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME'
}

export type CreateSessionMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSession: { __typename?: 'SessionWithToken', token: string, user: { __typename?: 'User', email: string, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } } };

export type CreateSessionWithJwtMutationVariables = Exact<{
  jwt: Scalars['String'];
}>;


export type CreateSessionWithJwtMutation = { __typename?: 'Mutation', createSessionWithJWT: { __typename?: 'SessionWithToken', token: string, user: { __typename?: 'User', email: string, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } } };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { __typename?: 'Query', config: { __typename?: 'Config', languages: { __typename?: 'LanguagesConfig', defaultLanguageTag: string, languages: Array<{ __typename?: 'LanguageConfig', tag: string, description: string }> } } };

export type FullTenantFragment = { __typename?: 'Tenant', id: string, createdAt: number, modifiedAt: number, name: string, active: boolean, services: { __typename?: 'TenantServices', service: string, api: { __typename?: 'TenantServicesApi', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, editor: { __typename?: 'TenantServicesEditor', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, frontends: { __typename?: 'TenantServicesFrontend', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string, basicAuth?: { __typename?: 'TenantBasicAuth', active: boolean, secretName: string, user?: string | null } | null } | null> }, media: { __typename?: 'TenantServicesMedia', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, mediaProxy: { __typename?: 'TenantMediaProxy', index: string, noIndex: string }, certificates: Array<{ __typename?: 'TenantSslCertificate', id: string, name: string } | null> } };

export type TenantListQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  sort?: InputMaybe<TenantSort>;
}>;


export type TenantListQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', totalCount: number, nodes: Array<{ __typename?: 'Tenant', id: string, createdAt: number, modifiedAt: number, name: string, active: boolean, services: { __typename?: 'TenantServices', service: string, api: { __typename?: 'TenantServicesApi', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, editor: { __typename?: 'TenantServicesEditor', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, frontends: { __typename?: 'TenantServicesFrontend', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string, basicAuth?: { __typename?: 'TenantBasicAuth', active: boolean, secretName: string, user?: string | null } | null } | null> }, media: { __typename?: 'TenantServicesMedia', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, mediaProxy: { __typename?: 'TenantMediaProxy', index: string, noIndex: string }, certificates: Array<{ __typename?: 'TenantSslCertificate', id: string, name: string } | null> } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type TenantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TenantQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', id: string, createdAt: number, modifiedAt: number, name: string, active: boolean, services: { __typename?: 'TenantServices', service: string, api: { __typename?: 'TenantServicesApi', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, editor: { __typename?: 'TenantServicesEditor', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, frontends: { __typename?: 'TenantServicesFrontend', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string, basicAuth?: { __typename?: 'TenantBasicAuth', active: boolean, secretName: string, user?: string | null } | null } | null> }, media: { __typename?: 'TenantServicesMedia', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, mediaProxy: { __typename?: 'TenantMediaProxy', index: string, noIndex: string }, certificates: Array<{ __typename?: 'TenantSslCertificate', id: string, name: string } | null> } } | null };

export type CreateTenantMutationVariables = Exact<{
  input: TenantInput;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', createTenant?: { __typename?: 'Tenant', id: string, createdAt: number, modifiedAt: number, name: string, active: boolean, services: { __typename?: 'TenantServices', service: string, api: { __typename?: 'TenantServicesApi', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, editor: { __typename?: 'TenantServicesEditor', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, frontends: { __typename?: 'TenantServicesFrontend', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string, basicAuth?: { __typename?: 'TenantBasicAuth', active: boolean, secretName: string, user?: string | null } | null } | null> }, media: { __typename?: 'TenantServicesMedia', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, mediaProxy: { __typename?: 'TenantMediaProxy', index: string, noIndex: string }, certificates: Array<{ __typename?: 'TenantSslCertificate', id: string, name: string } | null> } } | null };

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TenantInput;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', updateTenant?: { __typename?: 'Tenant', id: string, createdAt: number, modifiedAt: number, name: string, active: boolean, services: { __typename?: 'TenantServices', service: string, api: { __typename?: 'TenantServicesApi', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, editor: { __typename?: 'TenantServicesEditor', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, frontends: { __typename?: 'TenantServicesFrontend', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string, basicAuth?: { __typename?: 'TenantBasicAuth', active: boolean, secretName: string, user?: string | null } | null } | null> }, media: { __typename?: 'TenantServicesMedia', domainList: Array<{ __typename?: 'TenantServicesDomainList', id: string, ingressName: string, domain: string, certificate: string, protocol: string } | null> }, mediaProxy: { __typename?: 'TenantMediaProxy', index: string, noIndex: string }, certificates: Array<{ __typename?: 'TenantSslCertificate', id: string, name: string } | null> } } | null };

export type DeleteTenantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTenantMutation = { __typename?: 'Mutation', deleteTenant?: string | null };

export type FullUserFragment = { __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> };

export type UserListQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  sort?: InputMaybe<UserSort>;
}>;


export type UserListQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, nodes: Array<{ __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } | null };

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  password: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } | null };

export type ResetUserPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
}>;


export type ResetUserPasswordMutation = { __typename?: 'Mutation', resetUserPassword?: { __typename?: 'User', id: string, createdAt: number, modifiedAt: number, name: string, preferredName?: string | null, active: boolean, lastLogin?: string | null, email: string, address?: { __typename?: 'UserAddress', streetAddress: string, zipCode: string, city: string, country: string } | null, properties: Array<{ __typename?: 'Properties', key: string, value: string, public: boolean }>, roles: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: string | null };

export type FullPermissionFragment = { __typename?: 'Permission', id: string, description: string, deprecated: boolean };

export type FullUserRoleFragment = { __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> };

export type UserRoleListQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type UserRoleListQuery = { __typename?: 'Query', userRoles: { __typename?: 'UserRoleConnection', totalCount: number, nodes: Array<{ __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type PermissionListQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionListQuery = { __typename?: 'Query', permissions?: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> | null };

export type UserRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserRoleQuery = { __typename?: 'Query', userRole?: { __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> } | null };

export type CreateUserRoleMutationVariables = Exact<{
  input: UserRoleInput;
}>;


export type CreateUserRoleMutation = { __typename?: 'Mutation', createUserRole?: { __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> } | null };

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserRoleInput;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole?: { __typename?: 'UserRole', id: string, name: string, description?: string | null, systemRole: boolean, permissions: Array<{ __typename?: 'Permission', id: string, description: string, deprecated: boolean }> } | null };

export type DeleteUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserRoleMutation = { __typename?: 'Mutation', deleteUserRole?: string | null };

export const FullTenantFragmentDoc = gql`
    fragment FullTenant on Tenant {
  id
  createdAt
  modifiedAt
  name
  active
  services {
    api {
      domainList {
        id
        ingressName
        domain
        certificate
        protocol
      }
    }
    editor {
      domainList {
        id
        ingressName
        domain
        certificate
        protocol
      }
    }
    frontends {
      domainList {
        id
        ingressName
        domain
        certificate
        protocol
        basicAuth {
          active
          secretName
          user
        }
      }
    }
    media {
      domainList {
        id
        ingressName
        domain
        certificate
        protocol
      }
    }
    mediaProxy {
      index
      noIndex
    }
    certificates {
      id
      name
    }
    service
  }
}
    `;
export const FullPermissionFragmentDoc = gql`
    fragment FullPermission on Permission {
  id
  description
  deprecated
}
    `;
export const FullUserRoleFragmentDoc = gql`
    fragment FullUserRole on UserRole {
  id
  name
  description
  systemRole
  permissions {
    ...FullPermission
  }
}
    ${FullPermissionFragmentDoc}`;
export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  createdAt
  modifiedAt
  name
  preferredName
  address {
    streetAddress
    zipCode
    city
    country
  }
  active
  lastLogin
  properties {
    key
    value
    public
  }
  email
  roles {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export const CreateSessionDocument = gql`
    mutation CreateSession($email: String!, $password: String!) {
  createSession(email: $email, password: $password) {
    user {
      email
      roles {
        ...FullUserRole
      }
    }
    token
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const CreateSessionWithJwtDocument = gql`
    mutation CreateSessionWithJWT($jwt: String!) {
  createSessionWithJWT(jwt: $jwt) {
    user {
      email
      roles {
        ...FullUserRole
      }
    }
    token
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateSessionWithJwtMutationFn = Apollo.MutationFunction<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>;

/**
 * __useCreateSessionWithJwtMutation__
 *
 * To run a mutation, you first call `useCreateSessionWithJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionWithJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionWithJwtMutation, { data, loading, error }] = useCreateSessionWithJwtMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useCreateSessionWithJwtMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>(CreateSessionWithJwtDocument, options);
      }
export type CreateSessionWithJwtMutationHookResult = ReturnType<typeof useCreateSessionWithJwtMutation>;
export type CreateSessionWithJwtMutationResult = Apollo.MutationResult<CreateSessionWithJwtMutation>;
export type CreateSessionWithJwtMutationOptions = Apollo.BaseMutationOptions<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>;
export const ConfigDocument = gql`
    query Config {
  config {
    languages {
      defaultLanguageTag
      languages {
        tag
        description
      }
    }
  }
}
    `;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: Apollo.QueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
      }
export function useConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = Apollo.QueryResult<ConfigQuery, ConfigQueryVariables>;
export const TenantListDocument = gql`
    query TenantList($filter: String, $after: ID, $before: ID, $first: Int, $last: Int, $skip: Int, $order: SortOrder, $sort: TenantSort) {
  tenants(
    filter: {name: $filter}
    after: $after
    before: $before
    first: $first
    last: $last
    skip: $skip
    order: $order
    sort: $sort
  ) {
    nodes {
      ...FullTenant
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullTenantFragmentDoc}`;

/**
 * __useTenantListQuery__
 *
 * To run a query within a React component, call `useTenantListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTenantListQuery(baseOptions?: Apollo.QueryHookOptions<TenantListQuery, TenantListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantListQuery, TenantListQueryVariables>(TenantListDocument, options);
      }
export function useTenantListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantListQuery, TenantListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantListQuery, TenantListQueryVariables>(TenantListDocument, options);
        }
export type TenantListQueryHookResult = ReturnType<typeof useTenantListQuery>;
export type TenantListLazyQueryHookResult = ReturnType<typeof useTenantListLazyQuery>;
export type TenantListQueryResult = Apollo.QueryResult<TenantListQuery, TenantListQueryVariables>;
export const TenantDocument = gql`
    query Tenant($id: ID!) {
  tenant(id: $id) {
    ...FullTenant
  }
}
    ${FullTenantFragmentDoc}`;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantQuery(baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
      }
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
        }
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($input: TenantInput!) {
  createTenant(input: $input) {
    ...FullTenant
  }
}
    ${FullTenantFragmentDoc}`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($id: ID!, $input: TenantInput!) {
  updateTenant(id: $id, input: $input) {
    ...FullTenant
  }
}
    ${FullTenantFragmentDoc}`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($id: ID!) {
  deleteTenant(id: $id)
}
    `;
export type DeleteTenantMutationFn = Apollo.MutationFunction<DeleteTenantMutation, DeleteTenantMutationVariables>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMutation, DeleteTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMutation, DeleteTenantMutationVariables>(DeleteTenantDocument, options);
      }
export type DeleteTenantMutationHookResult = ReturnType<typeof useDeleteTenantMutation>;
export type DeleteTenantMutationResult = Apollo.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>;
export const UserListDocument = gql`
    query UserList($filter: String, $after: ID, $before: ID, $first: Int, $last: Int, $skip: Int, $order: SortOrder, $sort: UserSort) {
  users(
    filter: {text: $filter}
    after: $after
    before: $before
    first: $first
    last: $last
    skip: $skip
    order: $order
    sort: $sort
  ) {
    nodes {
      ...FullUser
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserListQuery(baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
      }
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserInput!, $password: String!) {
  createUser(input: $input, password: $password) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UserInput!) {
  updateUser(id: $id, input: $input) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($id: ID!, $password: String!) {
  resetUserPassword(id: $id, password: $password) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserRoleListDocument = gql`
    query UserRoleList($filter: String, $after: ID, $before: ID, $first: Int, $last: Int) {
  userRoles(
    filter: {name: $filter}
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    nodes {
      ...FullUserRole
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullUserRoleFragmentDoc}`;

/**
 * __useUserRoleListQuery__
 *
 * To run a query within a React component, call `useUserRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useUserRoleListQuery(baseOptions?: Apollo.QueryHookOptions<UserRoleListQuery, UserRoleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleListQuery, UserRoleListQueryVariables>(UserRoleListDocument, options);
      }
export function useUserRoleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleListQuery, UserRoleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleListQuery, UserRoleListQueryVariables>(UserRoleListDocument, options);
        }
export type UserRoleListQueryHookResult = ReturnType<typeof useUserRoleListQuery>;
export type UserRoleListLazyQueryHookResult = ReturnType<typeof useUserRoleListLazyQuery>;
export type UserRoleListQueryResult = Apollo.QueryResult<UserRoleListQuery, UserRoleListQueryVariables>;
export const PermissionListDocument = gql`
    query PermissionList {
  permissions {
    ...FullPermission
  }
}
    ${FullPermissionFragmentDoc}`;

/**
 * __usePermissionListQuery__
 *
 * To run a query within a React component, call `usePermissionListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionListQuery(baseOptions?: Apollo.QueryHookOptions<PermissionListQuery, PermissionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionListQuery, PermissionListQueryVariables>(PermissionListDocument, options);
      }
export function usePermissionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionListQuery, PermissionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionListQuery, PermissionListQueryVariables>(PermissionListDocument, options);
        }
export type PermissionListQueryHookResult = ReturnType<typeof usePermissionListQuery>;
export type PermissionListLazyQueryHookResult = ReturnType<typeof usePermissionListLazyQuery>;
export type PermissionListQueryResult = Apollo.QueryResult<PermissionListQuery, PermissionListQueryVariables>;
export const UserRoleDocument = gql`
    query UserRole($id: ID!) {
  userRole(id: $id) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions: Apollo.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
      }
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const CreateUserRoleDocument = gql`
    mutation CreateUserRole($input: UserRoleInput!) {
  createUserRole(input: $input) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateUserRoleMutationFn = Apollo.MutationFunction<CreateUserRoleMutation, CreateUserRoleMutationVariables>;

/**
 * __useCreateUserRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRoleMutation, { data, loading, error }] = useCreateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRoleMutation, CreateUserRoleMutationVariables>(CreateUserRoleDocument, options);
      }
export type CreateUserRoleMutationHookResult = ReturnType<typeof useCreateUserRoleMutation>;
export type CreateUserRoleMutationResult = Apollo.MutationResult<CreateUserRoleMutation>;
export type CreateUserRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($id: ID!, $input: UserRoleInput!) {
  updateUserRole(id: $id, input: $input) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const DeleteUserRoleDocument = gql`
    mutation DeleteUserRole($id: ID!) {
  deleteUserRole(id: $id)
}
    `;
export type DeleteUserRoleMutationFn = Apollo.MutationFunction<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;

/**
 * __useDeleteUserRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRoleMutation, { data, loading, error }] = useDeleteUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>(DeleteUserRoleDocument, options);
      }
export type DeleteUserRoleMutationHookResult = ReturnType<typeof useDeleteUserRoleMutation>;
export type DeleteUserRoleMutationResult = Apollo.MutationResult<DeleteUserRoleMutation>;
export type DeleteUserRoleMutationOptions = Apollo.BaseMutationOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;