import React, {useState, useEffect} from 'react'

import {Button, CheckPicker, Form, Message, Modal, toaster, Toggle} from 'rsuite'

import {
  useCreateUserMutation,
  FullUserFragment,
  useUpdateUserMutation,
  useUserQuery,
  useUserRoleListQuery,
  FullUserRoleFragment,
} from '../api'

import {ResetUserPasswordPanel} from './resetUserPasswordPanel'

import {useTranslation} from 'react-i18next'

export interface UserEditPanelProps {
  id?: string

  onClose?(): void
  onSave?(user: FullUserFragment): void
}

export function UserEditPanel({id, onClose, onSave}: UserEditPanelProps) {
  const [name, setName] = useState('')
  const [preferredName, setPreferredName] = useState<string | undefined>()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [active, setActive] = useState(true)
  const [roles, setRoles] = useState<FullUserRoleFragment[]>([])
  const [userRoles, setUserRoles] = useState<FullUserRoleFragment[]>([])

  const [isResetUserPasswordOpen, setIsResetUserPasswordOpen] = useState(false)

  const {
    data,
    loading: isLoading,
    error: loadError,
  } = useUserQuery({
    variables: {id: id!},
    fetchPolicy: 'network-only',
    skip: id === undefined,
  })

  const {
    data: userRoleData,
    loading: isUserRoleLoading,
    error: loadUserRoleError,
  } = useUserRoleListQuery({
    fetchPolicy: 'network-only',
    variables: {
      first: 200,
    },
  })

  const [createUser, {loading: isCreating, error: createError}] = useCreateUserMutation()
  const [updateUser, {loading: isUpdating, error: updateError}] = useUpdateUserMutation()

  const isDisabled =
    isLoading || isUserRoleLoading || isCreating || isUpdating || loadError !== undefined

  const {t} = useTranslation()

  useEffect(() => {
    if (data?.user) {
      setName(data.user.name)
      setPreferredName(data.user.preferredName ?? undefined)
      setEmail(data.user.email)
      setActive(data.user.active)
      if (data.user.roles) {
        setRoles(data.user.roles as FullUserRoleFragment[])
      }
    }
  }, [data?.user])

  useEffect(() => {
    if (userRoleData?.userRoles?.nodes) {
      setUserRoles(userRoleData.userRoles.nodes)
    }
  }, [userRoleData?.userRoles])

  useEffect(() => {
    const error =
      loadError?.message ??
      createError?.message ??
      updateError?.message ??
      loadUserRoleError?.message
    if (error) {
      toaster.push(
        <Message closable type="error" duration={0}>
          {error}
        </Message>
      )
    }
  }, [loadError, createError, updateError, loadUserRoleError])

  async function handleSave() {
    if (id && data?.user) {
      const {data: updateData} = await updateUser({
        variables: {
          id,
          input: {
            name,
            preferredName,
            email,
            active,
            properties: data.user.properties,
            roleIDs: roles.map((role) => role.id),
          },
        },
      })

      if (updateData?.updateUser) onSave?.(updateData.updateUser)
    } else {
      const {data: createData} = await createUser({
        variables: {
          input: {
            name,
            preferredName,
            email,
            active,
            properties: [],
            roleIDs: roles.map((role) => role.id),
          },
          password,
        },
      })
      if (createData?.createUser) onSave?.(createData.createUser)
    }
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {id ? t('userList.panels.editUser') : t('userList.panels.createUser')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel>{t('userList.panels.name')}</Form.ControlLabel>
            <Form.Control
              name={t('userList.panels.name')}
              value={name}
              disabled={isDisabled}
              onChange={(value) => setName(value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{t('userList.panels.preferredName')}</Form.ControlLabel>
            <Form.Control
              name={t('userList.panels.preferredName')}
              value={preferredName}
              disabled={isDisabled}
              onChange={(value) => setPreferredName(value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{t('userList.panels.email')}</Form.ControlLabel>
            <Form.Control
              name={t('userList.panels.email')}
              value={email}
              disabled={isDisabled}
              onChange={(value) => setEmail(value)}
            />
          </Form.Group>

          {!id ? (
            <Form.Group>
              <Form.ControlLabel>{t('userList.panels.password')}</Form.ControlLabel>
              <Form.Control
                type="password"
                name={t('userList.panels.password')}
                value={password}
                disabled={isDisabled}
                onChange={(value) => setPassword(value)}
              />
            </Form.Group>
          ) : (
            <Form.Group>
              <Button appearance="primary" onClick={() => setIsResetUserPasswordOpen(true)}>
                {t('userList.panels.resetPassword')}
              </Button>
            </Form.Group>
          )}

          <Form.Group>
            <Form.ControlLabel>{t('userList.panels.userRoles')}</Form.ControlLabel>
            <CheckPicker
              name={t('userList.panels.userRoles')}
              block={true}
              value={roles.map((role) => role.id)}
              data={userRoles.map((userRole) => ({value: userRole.id, label: userRole.name}))}
              onChange={(value) => {
                setRoles(userRoles.filter((userRole) => value.includes(userRole.id)))
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{t('userList.panels.active')}</Form.ControlLabel>
            <Toggle checked={active} disabled={isDisabled} onChange={(value) => setActive(value)} />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer classPrefix="wep-modal-footer">
        <Button appearance={'primary'} disabled={isDisabled} onClick={() => handleSave()}>
          {id ? t('userList.panels.save') : t('userList.panels.create')}
        </Button>
        <Button appearance={'subtle'} onClick={() => onClose?.()}>
          {t('userList.panels.cancel')}
        </Button>
      </Modal.Footer>

      <Modal open={isResetUserPasswordOpen} onClose={() => setIsResetUserPasswordOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('userList.panels.resetPassword')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ResetUserPasswordPanel
            userID={id}
            userName={name}
            onClose={() => setIsResetUserPasswordOpen(false)}
          />
        </Modal.Body>

        <Modal.Footer classPrefix="wep-modal-footer">
          <Button onClick={() => setIsResetUserPasswordOpen(false)} appearance="subtle">
            {t('userList.panels.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
