import React from 'react'

export interface AppContextInitialState {
  uiLanguage: string
}

const key = 'k8sBackoffice/app-context'
const stickyValue = typeof window !== 'undefined' ? window.localStorage.getItem(key) : ''
export const appContextInitialState: AppContextInitialState = {
  uiLanguage: 'de',
  ...(stickyValue ? JSON.parse(stickyValue) : {}),
}

interface AppContextStateAction {
  type: string
}

interface AppContextStateActionSetLanguage extends AppContextStateAction {
  type: 'language'
  uiLanguage: string
}
export type AppContextStateActions = AppContextStateActionSetLanguage

export function appContextReducer(
  state: AppContextInitialState,
  action: AppContextStateActions
): AppContextInitialState {
  switch (action.type) {
    case 'language': {
      const newState = {...state, uiLanguage: action.uiLanguage}
      window.localStorage.setItem(key, JSON.stringify(newState))
      return newState
    }
    default:
      throw new Error()
  }
}

export const AppContext = React.createContext<
  [AppContextInitialState, React.Dispatch<AppContextStateActions>]
>([appContextInitialState, undefined!])
