import React, {ReactNode, useContext} from 'react'

import {AuthContext, AuthDispatchContext, AuthDispatchActionType} from './authContext'
import {useMutation, gql} from '@apollo/client'
import {LocalStorageKey} from './utility'
import {Button, IconButton} from 'rsuite'
import {
  route,
  routePath,
  required,
  zeroOrMore,
  createRouteContext,
  RouteInstancesForRoutes,
  RouteActionType,
  fullPathForRoute,
} from './router'

export enum RouteType {
  Login = 'login',
  Logout = 'logout',

  Index = 'index',
  NotFound = 'notFound',

  UserList = 'userList',
  UserEdit = 'userEdit',
  UserCreate = 'userCreate',

  UserRoleList = 'userRoleList',
  UserRoleEdit = 'userRoleEdit',
  UserRoleCreate = 'userRoleCreate',

  TenantList = 'tenantList',
  TenantEdit = 'tenantEdit',
  TenantCreate = 'tenantCreate',

  CopyData = 'copyData',
}

export const IndexRoute = route(RouteType.Index, routePath`/`)
export const LoginRoute = route(RouteType.Login, routePath`/login`)
export const LoginJWTRoute = route(RouteType.Login, routePath`/login/jwt`)
export const LogoutRoute = route(RouteType.Logout, routePath`/logout`)

export const UserListRoute = route(RouteType.UserList, routePath`/users`)
export const UserEditRoute = route(RouteType.UserEdit, routePath`/user/edit/${required('id')}`)
export const UserCreateRoute = route(RouteType.UserCreate, routePath`/user/create`)

export const UserRoleListRoute = route(RouteType.UserRoleList, routePath`/userroles`)
export const UserRoleEditRoute = route(
  RouteType.UserRoleEdit,
  routePath`/userrole/edit/${required('id')}`
)
export const UserRoleCreateRoute = route(RouteType.UserRoleCreate, routePath`/userrole/create`)

export const TenantListRoute = route(RouteType.TenantList, routePath`/tenants`)
export const TenantEditRoute = route(
  RouteType.TenantEdit,
  routePath`/tenant/edit/${required('id')}`
)
export const TenantCreateRoute = route(RouteType.TenantCreate, routePath`/tenant/create`)

export const CopyDataRoute = route(RouteType.CopyData, routePath`/copydata`)

export const NotFoundRoute = route(RouteType.NotFound, routePath`/${zeroOrMore('path')}`, null)

export const routes = [
  IndexRoute,
  LoginRoute,
  LoginJWTRoute,
  LogoutRoute,
  UserListRoute,
  UserEditRoute,
  UserCreateRoute,
  UserRoleListRoute,
  UserRoleEditRoute,
  UserRoleCreateRoute,
  TenantListRoute,
  TenantEditRoute,
  TenantCreateRoute,
  CopyDataRoute,
  NotFoundRoute,
] as const

export const {
  Link,
  routeLink,
  RouteProvider: BaseRouteProvider,
  matchRoute,
  useRoute,
  useRouteDispatch,
} = createRouteContext(routes)

export const ButtonLink = routeLink(Button)
export const IconButtonLink = routeLink(IconButton)

export type Route = RouteInstancesForRoutes<typeof routes>

export interface RouteProviderProps {
  readonly children?: ReactNode
}

const LogoutMutation = gql`
  mutation Logout {
    revokeActiveSession
  }
`

export function RouteProvider({children}: RouteProviderProps) {
  const [logout] = useMutation(LogoutMutation)
  const {session} = useContext(AuthContext)
  const authDispatch = useContext(AuthDispatchContext)

  return (
    <BaseRouteProvider
      handleNextRoute={(next, dispatch) => {
        // TODO: Handle UnsavedChangesDialog popstate
        // TODO: Add a way to discard next route
        if (next.type === RouteType.Logout) {
          if (session) {
            logout().catch((error) => console.warn('Error logging out ', error))
            localStorage.removeItem(LocalStorageKey.SessionToken)
            authDispatch({type: AuthDispatchActionType.Logout})
          }

          dispatch({type: RouteActionType.ReplaceRoute, route: LoginRoute.create({})})
        } else if (next.type === RouteType.Login) {
          if (session) {
            dispatch({type: RouteActionType.SetCurrentRoute, route: IndexRoute.create({})})
          } else {
            dispatch({
              type: RouteActionType.SetCurrentRoute,
              route: next,
            })
          }
        } else {
          if (session) {
            dispatch({type: RouteActionType.SetCurrentRoute, route: next})
          } else {
            dispatch({
              type: RouteActionType.SetCurrentRoute,
              route: LoginRoute.create(
                {},
                {
                  query: next.type !== RouteType.Index ? {next: fullPathForRoute(next)} : undefined,
                }
              ),
            })
          }
        }

        return () => {
          /* do nothing */
        }
      }}
    >
      {children}
    </BaseRouteProvider>
  )
}
