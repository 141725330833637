import React, {useEffect, useReducer, useState} from 'react'
import {hot} from 'react-hot-loader/root'

import {useRoute, RouteType, Route, TenantEditRoute, useRouteDispatch} from './route'

import {Login} from './login'
import {Base} from './base'

import {UserList} from './routes/userList'
import {UserRoleList} from './routes/userRoleList'

import {AppContext, appContextInitialState, appContextReducer} from './control/appContext'
import {TenantList} from './routes/tenantList'
import {TenantEditPanel} from './panel/tenantEditPanel'

import {CopyData} from './routes/copyData'

import {postData} from './utility'
import {RouteActionType} from './router'

function contentForRoute(route: Route) {
  switch (route.type) {
    case RouteType.Login:
      return <Login />

    case RouteType.UserList:
    case RouteType.UserCreate:
    case RouteType.UserEdit:
      return <UserList />

    case RouteType.UserRoleList:
    case RouteType.UserRoleCreate:
    case RouteType.UserRoleEdit:
      return <UserRoleList />

    case RouteType.TenantList:
      return <TenantList />

    case RouteType.CopyData:
      return <CopyData />

    case RouteType.NotFound:
      return null

    default:
      return <TenantList />
  }
}

export function App() {
  const appContext = useReducer(appContextReducer, appContextInitialState)
  const dispatch = useRouteDispatch()

  const [response, setResponse] = useState<Response | null>(null)

  useEffect(() => {
    setResponse(null)
  })

  const {current} = useRoute()
  if (!current) {
    return null
  }

  let comp = null
  switch (current.type) {
    case RouteType.Login:
      comp = <Login />
      break

    case RouteType.TenantCreate:
    case RouteType.TenantEdit:
      comp = (
        <TenantEditPanel
          onSave={async (tenant, oldData) => {
            if (!tenant.active && oldData?.tenant?.active) {
              const res = await postData(
                '/kubectl-delete-all',
                JSON.stringify({tenant: tenant, oldTenant: oldData?.tenant})
              )

              setResponse(res.response)
            } else {
              const res = await postData(
                '/kubectl',
                JSON.stringify({tenant: tenant, oldTenant: oldData?.tenant})
              )

              setResponse(res.response)
            }

            dispatch({
              type: RouteActionType.PushRoute,
              route: TenantEditRoute.create({id: tenant.id}),
            })
          }}
          id={current.type === RouteType.TenantEdit ? current.params.id : undefined}
          response={response}
        />
      )
      break

    default:
      comp = <Base>{contentForRoute(current)}</Base>
  }

  return <AppContext.Provider value={appContext}>{comp}</AppContext.Provider>
}

export const HotApp = hot(App)
