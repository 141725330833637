import React, {useEffect, useState} from 'react'

import {
  ButtonLink,
  Link,
  RouteType,
  UserCreateRoute,
  UserEditRoute,
  UserListRoute,
  useRoute,
  useRouteDispatch,
} from '../route'

import {FullUserFragment, useDeleteUserMutation, UserSort, useUserListQuery} from '../api'
import {UserEditPanel} from '../panel/userEditPanel'
import {ResetUserPasswordPanel} from '../panel/resetUserPasswordPanel'

import {useTranslation} from 'react-i18next'
import {
  Button,
  FlexboxGrid,
  Input,
  InputGroup,
  Modal,
  Table,
  Popover,
  Whisper,
  Divider,
  Pagination,
} from 'rsuite'
import {DEFAULT_TABLE_PAGE_SIZES, formatDate, mapTableSortTypeToGraphQLSortOrder} from '../utility'
import {RouteActionType} from '../router'

import Search from '@rsuite/icons/legacy/Search'

const {Column, HeaderCell, Cell} = Table

function mapColumFieldToGraphQLField(columnField: string): UserSort | null {
  switch (columnField) {
    case 'createdAt':
      return UserSort.CreatedAt
    case 'modifiedAt':
      return UserSort.ModifiedAt
    case 'name':
      return UserSort.Name
    default:
      return null
  }
}

export function UserList() {
  const {current} = useRoute()
  const dispatch = useRouteDispatch()

  const [isEditModalOpen, setEditModalOpen] = useState(
    current?.type === RouteType.UserEdit || current?.type === RouteType.UserCreate
  )

  const [editID, setEditID] = useState<string | undefined>(
    current?.type === RouteType.UserEdit ? current.params.id : undefined
  )

  const [filter, setFilter] = useState('')

  const [isResetUserPasswordOpen, setIsResetUserPasswordOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<FullUserFragment>()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sortField, setSortField] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [users, setUsers] = useState<FullUserFragment[]>([])

  const {
    data,
    refetch,
    loading: isLoading,
  } = useUserListQuery({
    variables: {
      filter: filter || undefined,
      first: limit,
      skip: page - 1,
      sort: mapColumFieldToGraphQLField(sortField),
      order: mapTableSortTypeToGraphQLSortOrder(sortOrder),
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    refetch({
      filter: filter || undefined,
      first: limit,
      skip: page - 1,
      sort: mapColumFieldToGraphQLField(sortField),
      order: mapTableSortTypeToGraphQLSortOrder(sortOrder),
    })
  }, [filter, page, limit, sortOrder, sortField, refetch])

  const [deleteUser, {loading: isDeleting}] = useDeleteUserMutation()

  const {t} = useTranslation()

  useEffect(() => {
    document.title = t('userList.overview.users')
  })

  const rowDeleteButton = (rowData: any) => {
    const triggerRef = React.createRef<any>()
    const close = () => triggerRef.current.close()
    const speaker = (
      <Popover title={currentUser?.name}>
        <Button
          color="red"
          disabled={isDeleting}
          onClick={() => {
            if (!currentUser) return
            close()
            deleteUser({
              variables: {id: currentUser.id},
            })
              .then(() => {
                refetch()
              })
              .catch(console.error)
          }}
        >
          {t('global.buttons.deleteNow')}
        </Button>
      </Popover>
    )
    return (
      <>
        <Whisper placement="left" trigger="click" speaker={speaker} ref={triggerRef}>
          <Button
            style={{padding: '0'}}
            appearance="link"
            color="red"
            onClick={() => {
              setCurrentUser(rowData)
            }}
          >
            {t('global.buttons.delete')}
          </Button>
        </Whisper>
      </>
    )
  }

  useEffect(() => {
    if (current?.type === RouteType.UserCreate) {
      setEditID(undefined)
      setEditModalOpen(true)
    }

    if (current?.type === RouteType.UserEdit) {
      setEditID(current.params.id)
      setEditModalOpen(true)
    }
  }, [current])

  useEffect(() => {
    if (data?.users?.nodes) {
      setUsers(data.users.nodes)
      if (data.users.totalCount + 9 < page * limit) {
        setPage(1)
      }
    }
  }, [data?.users, limit, page])

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <h2>{t('userList.overview.users')}</h2>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} style={{textAlign: 'right'}}>
          <ButtonLink appearance="primary" disabled={isLoading} route={UserCreateRoute.create({})}>
            {t('userList.overview.newUser')}
          </ButtonLink>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} style={{marginTop: '20px'}}>
          <InputGroup>
            <InputGroup.Addon>
              <Search />
            </InputGroup.Addon>
            <Input value={filter} onChange={(value) => setFilter(value)} />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Table
        style={{marginTop: '20px', overflowY: 'auto'}}
        height={510}
        autoHeight={true}
        loading={isLoading}
        data={users}
        sortColumn={sortField}
        sortType={sortOrder}
        onSortColumn={(sortColumn, sortType) => {
          if (sortType) setSortOrder(sortType)
          if (sortColumn) setSortField(sortColumn)
        }}
      >
        <Column flexGrow={2} align="left" sortable>
          <HeaderCell>{t('userList.overview.name')}</HeaderCell>
          <Cell dataKey={'name'}>
            {(rowData: FullUserFragment) => (
              <Link route={UserEditRoute.create({id: rowData.id})}>
                {rowData.name || t('userList.overview.unknown')}
              </Link>
            )}
          </Cell>
        </Column>
        <Column flexGrow={2} align="left" sortable>
          <HeaderCell>{t('email')}</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={1} minWidth={140} align="left" sortable>
          <HeaderCell>{t('userList.overview.createdAt')}</HeaderCell>
          <Cell dataKey="createdAt">
            {({createdAt}: FullUserFragment) => formatDate(createdAt)}
          </Cell>
        </Column>
        <Column flexGrow={1} minWidth={140} align="left" sortable>
          <HeaderCell>{t('userList.overview.modifiedAt')}</HeaderCell>
          <Cell dataKey="modifiedAt">
            {({modifiedAt}: FullUserFragment) => formatDate(modifiedAt)}
          </Cell>
        </Column>

        <Column width={200} align="right" fixed="right">
          <HeaderCell>{t('global.buttons.action')}</HeaderCell>
          <Cell>
            {(rowData: FullUserFragment) => (
              <>
                <Button
                  style={{padding: '0'}}
                  color="orange"
                  appearance="link"
                  onClick={(e) => {
                    setCurrentUser(rowData)
                    setIsResetUserPasswordOpen(true)
                  }}
                >
                  {t('userList.overview.reset')}
                </Button>{' '}
                <Divider vertical></Divider> {rowDeleteButton(rowData)}
              </>
            )}
          </Cell>
        </Column>
      </Table>

      <Pagination
        style={{padding: '20px 0', display: 'flex', justifyContent: 'flex-end'}}
        layout={['limit', '|', 'total', '|', 'pager']}
        size="xs"
        prev
        next
        first
        last
        ellipsis
        total={data?.users.totalCount || 0}
        limit={limit}
        limitOptions={DEFAULT_TABLE_PAGE_SIZES}
        maxButtons={5}
        activePage={page}
        onChangePage={(page: React.SetStateAction<number>) => {
          setPage(page)
        }}
        onChangeLimit={(limit: number) => {
          setLimit(limit)
        }}
      />

      {/* Modal: Create/edit user */}
      <Modal
        size={'sm'}
        open={isEditModalOpen}
        onClose={() => {
          setEditModalOpen(false)
          dispatch({
            type: RouteActionType.PushRoute,
            route: UserListRoute.create({}, current ?? undefined),
          })
        }}
      >
        <UserEditPanel
          id={editID!}
          onClose={() => {
            setEditModalOpen(false)
            dispatch({
              type: RouteActionType.PushRoute,
              route: UserListRoute.create({}, current ?? undefined),
            })
          }}
          onSave={() => {
            setEditModalOpen(false)
            refetch()
            dispatch({
              type: RouteActionType.PushRoute,
              route: UserListRoute.create({}, current ?? undefined),
            })
          }}
        />
      </Modal>

      {/* Modal: Reset password */}
      <Modal open={isResetUserPasswordOpen} onClose={() => setIsResetUserPasswordOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('userList.panels.resetPassword')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ResetUserPasswordPanel
            userID={currentUser?.id}
            userName={currentUser?.name}
            onClose={() => setIsResetUserPasswordOpen(false)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setIsResetUserPasswordOpen(false)} appearance="subtle">
            {t('userList.panels.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
