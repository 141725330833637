import {DocumentNode, OperationDefinitionNode} from 'graphql'
import {SortOrder} from './api'
import fetch from 'node-fetch'
import date from 'date-and-time'
import {customAlphabet} from 'nanoid'

export enum LocalStorageKey {
  SessionToken = 'sessionToken',
}

export const IDAlphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const generateID = customAlphabet(IDAlphabet, 16)

export function getOperationNameFromDocument(node: DocumentNode) {
  const firstOperation = node.definitions.find(
    (node) => node.kind === 'OperationDefinition'
  ) as OperationDefinitionNode

  if (!firstOperation?.name?.value) throw new Error("Coulnd't find operation name.")
  return firstOperation.name.value
}

export type SortType = 'asc' | 'desc' | null
export function mapTableSortTypeToGraphQLSortOrder(sortType: SortType): SortOrder | null {
  switch (sortType) {
    case 'desc':
      return SortOrder.Descending
    case 'asc':
      return SortOrder.Ascending
    default:
      return null
  }
}

export const DEFAULT_TABLE_PAGE_SIZES = [10, 20, 50, 100]

export function formatDate(dateString: string | number) {
  return date.format(new Date(dateString), 'DD.MM.YYYY HH:mm:ss')
}

export async function postData(path: string, data: string) {
  const res = await fetch(path, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: data,
  })
    .then(async (res: any) => {
      return {
        response: res,
        data: await res.text(),
      }
    })
    .catch((e) => {
      return {
        response: {
          status: 500,
          statusText: e,
        },
        data: e,
      }
    })

  return res
}

export function isFunctionalUpdate<T>(value: React.SetStateAction<T>): value is (value: T) => T {
  return typeof value === 'function'
}
