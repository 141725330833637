import React, {useState, useEffect} from 'react'
import {
  Form,
  IconButton,
  Input,
  SelectPicker,
  Toggle,
  Grid,
  Row,
  Col,
  toaster,
  Message,
} from 'rsuite'

import {
  FullTenantFragment,
  useUpdateTenantMutation,
  useCreateTenantMutation,
  useTenantQuery,
  Tenant,
  TenantServicesDomainList,
  TenantServices,
  TenantQuery,
  TenantSslCertificate,
} from '../api'

import {useTranslation} from 'react-i18next'
import {ListInput, ListValue} from '../atoms/listInput'
import {EditorTemplate} from '../atoms/editorTemplate'
import {NavigationBar} from '../atoms/navigationBar'
import {IconButtonLink, TenantListRoute} from '../route'
import {CertEditPanel} from './certEditPanel'
import {slugifyString} from '../../shared/utility'

import ArrowLeft from '@rsuite/icons/legacy/ArrowLeft'
import Save from '@rsuite/icons/legacy/Save'
import {OverlayLoader} from '../atoms/loader'
import {generateID} from '../utility'
import {ClientSettings} from '../../shared/types'
import {ElementID} from '../../shared/elementID'

export interface TenantEditPanelProps {
  id?: string
  response?: Response | null

  onSave(tenant: FullTenantFragment, oldData: TenantQuery | undefined): void
}

export interface TenantSslCertificateFe extends TenantSslCertificate {
  publicKey?: string
  privateKey?: string
}

export function TenantEditPanel({id, response, onSave}: TenantEditPanelProps) {
  const {t} = useTranslation()

  const [name, setName] = useState('')
  const [active, setActive] = useState(true)

  const [loading, setLoading] = useState(false)

  const [apiDomains, setApiDomains] = useState<ListValue<TenantServicesDomainList>[]>([])
  const [editorDomains, setEditorDomains] = useState<ListValue<TenantServicesDomainList>[]>([])
  const [frontendDomains, setFrontendDomains] = useState<ListValue<TenantServicesDomainList>[]>([])
  const [mediaDomains, setMediaDomains] = useState<ListValue<TenantServicesDomainList>[]>([])
  const [mediaProxyIndex, setMediaProxyIndex] = useState(
    `website-media-index-${slugifyString(generateID())}`
  )
  const [mediaProxyNoIndex, setMediaProxyNoIndex] = useState(
    `website-media-no-index-${slugifyString(generateID())}`
  )

  const [selectCerts, setSelectCerts] = useState<ListValue<any>[]>([])
  const [certs, setCerts] = useState<ListValue<TenantSslCertificateFe>[]>([])

  const pageTitle = id ? t('tenantList.panels.editTenant') : t('tenantList.panels.createTenant')

  const protocols = [
    {
      id: '1',
      label: 'https://',
      value: 'https://',
    },
    {
      id: '2',
      label: 'http://',
      value: 'http://',
    },
  ]

  const {
    data,
    loading: isLoading,
    error: loadError,
  } = useTenantQuery({
    variables: {id: id!},
    fetchPolicy: 'network-only',
    skip: id === undefined,
  })

  const [createTenant, {loading: isCreating, error: createError}] = useCreateTenantMutation()
  const [updateTenant, {loading: isUpdating, error: updateError}] = useUpdateTenantMutation()

  const isDisabled = loading || isLoading || isCreating || isUpdating || loadError !== undefined

  async function getDomainList(list: any[]): Promise<ListValue<TenantServicesDomainList>[]> {
    return list.map((item) => {
      return {
        id: item.id,
        value: item,
      }
    })
  }

  async function getCertList(list: any[]): Promise<ListValue<TenantSslCertificate>[]> {
    return list.map((item) => {
      return {
        id: item.id,
        value: item,
      }
    })
  }

  const {showTenantSslCert}: ClientSettings = JSON.parse(
    document.getElementById(ElementID.Settings)!.textContent!
  )

  useEffect(() => {
    setName(data?.tenant?.name ?? '')
    setActive(data?.tenant?.active ?? true)

    const tenant = data?.tenant?.services as TenantServices

    const fetchData = async () => {
      if (tenant && tenant.editor && tenant.editor.domainList) {
        setEditorDomains(await getDomainList(tenant.editor.domainList))
      }

      if (tenant && tenant.api && tenant.api.domainList) {
        setApiDomains(await getDomainList(tenant.api.domainList))
      }

      if (tenant && tenant.frontends && tenant.frontends.domainList) {
        setFrontendDomains(await getDomainList(tenant.frontends.domainList))
      }

      if (tenant && tenant.media && tenant.media.domainList) {
        setMediaDomains(await getDomainList(tenant.media.domainList))
      }

      if (tenant && tenant.certificates) {
        setCerts(await getCertList(tenant.certificates))
      }

      if (tenant && tenant.mediaProxy.index) {
        setMediaProxyIndex(tenant.mediaProxy.index)
      }

      if (tenant && tenant.mediaProxy.noIndex) {
        setMediaProxyNoIndex(tenant.mediaProxy.noIndex)
      }
    }

    fetchData().catch(console.error)
  }, [data, data?.tenant])

  useEffect(() => {
    const selectCerts = certs
      .filter((item) => {
        if (item.value.name && item.id) {
          return item
        }
        return null
      })
      .map((item) => {
        const label = item.value.name.split('-wpid-')[0]

        return {
          id: item.id,
          value: slugifyString(item.value.name),
          label: label,
        }
      })

    selectCerts.unshift({id: '1', value: `letsencrypt`, label: `Let’s Encrypt`})

    if (selectCerts) setSelectCerts(selectCerts)
  }, [certs])

  useEffect(() => {
    const error = loadError?.message ?? createError?.message ?? updateError?.message
    if (error) {
      toaster.push(
        <Message closable type="error" duration={0}>
          {error}
        </Message>
      )
    }
  }, [loadError, createError, updateError])

  useEffect(() => {
    setLoading(false)

    if (response) {
      if (response?.status !== 200) {
        toaster.push(
          <Message closable type="error" duration={0}>
            {t('global.error.apply')}
          </Message>
        )
      } else {
        toaster.push(
          <Message type="success" duration={2000}>
            {t('global.success.apply')}
          </Message>
        )
      }
    }
  }, [response, t])

  useEffect(() => {
    document.title = pageTitle
  })

  async function handleSave() {
    if (!name) {
      toaster.push(
        <Message closable type="error" duration={3000}>
          {t('tenantList.panels.nameNotSet')}
        </Message>
      )

      setLoading(false)
      return
    }

    const dbInput = {
      name: '',
      services: {
        api: {
          domainList: apiDomains?.map((item) => {
            return {
              id: item.id,
              ingressName: `api-${slugifyString(item.id)}`,
              domain: item.value.domain,
              certificate: item.value.certificate,
              protocol: item.value.protocol,
            }
          }),
        },
        editor: {
          domainList: editorDomains?.map((item) => {
            return {
              id: item.id,
              ingressName: `editor-${slugifyString(item.id)}`,
              domain: item.value?.domain,
              certificate: item.value?.certificate,
              protocol: item.value?.protocol,
            }
          }),
        },
        frontends: {
          domainList: frontendDomains?.map((item) => {
            return {
              id: item.id,
              ingressName: `frontend-${slugifyString(item.id)}`,
              domain: item.value?.domain,
              certificate: item.value?.certificate,
              protocol: item.value?.protocol,
              basicAuth: {
                active: item.value?.basicAuth?.active,
                secretName: `basic-auth-${slugifyString(item.id)}`,
                user: item.value?.basicAuth?.user,
              },
            }
          }),
        },
        media: {
          domainList: mediaDomains?.map((item) => {
            return {
              id: item.id,
              ingressName: `media-${slugifyString(item.id)}`,
              domain: item.value.domain,
              certificate: item.value.certificate,
              protocol: item.value.protocol,
            }
          }),
        },
        certificates: certs.map((item) => {
          return {
            id: item.id,
            name: slugifyString(item.value.name),
          }
        }),
        mediaProxy: {
          index: mediaProxyIndex,
          noIndex: mediaProxyNoIndex,
        },

        service: '',
      },
      active: active,
    } as Tenant

    const kubernetesConfigInput = JSON.parse(JSON.stringify(dbInput))

    kubernetesConfigInput.services.certificates = certs.map((item) => {
      return {
        id: item.id,
        name: slugifyString(item.value.name),
        privateKey: item.value.privateKey,
        publicKey: item.value.publicKey,
      }
    })

    kubernetesConfigInput.services.frontends.domainList = frontendDomains?.map((item) => {
      return {
        id: item.id,
        ingressName: `frontend-${slugifyString(item.id)}`,
        domain: item.value?.domain,
        certificate: item.value?.certificate,
        protocol: item.value?.protocol,
        basicAuth: {
          active: item.value?.basicAuth?.active,
          secretName: `basic-auth-${slugifyString(item.id)}`,
          user: item.value?.basicAuth?.user,
          pass: item.value?.basicAuth?.pass,
        },
      }
    })

    if (id && data?.tenant) {
      kubernetesConfigInput.name = data.tenant.name

      dbInput.name = data.tenant.name

      const {data: updateData} = await updateTenant({
        variables: {
          id: id,
          input: dbInput,
        },
      })

      if (updateData?.updateTenant) onSave({id: id, ...kubernetesConfigInput}, data)
    } else {
      kubernetesConfigInput.name = name

      dbInput.name = name
      const {data: createData} = await createTenant({
        variables: {
          input: dbInput,
        },
      })

      if (createData?.createTenant)
        onSave({id: createData.createTenant.id, ...kubernetesConfigInput}, data)
    }
  }

  if (isLoading) return <>loading...</>

  function domainItem(value: any, onChange: any, hasBasicAuth = false) {
    return (
      <Grid fluid>
        <Row className="show-grid">
          <Col xsHidden xs={5}>
            <SelectPicker
              data={protocols}
              defaultValue={value.protocol}
              onChange={(protocol) => {
                onChange({...value, protocol})
              }}
              searchable={false}
              disabled={isDisabled}
              block
            />
          </Col>
          <Col xsHidden xs={19}>
            <Input
              placeholder={t('tenantList.panels.domain')}
              value={value.domain}
              onChange={(domain) => {
                onChange({...value, domain})
              }}
            />
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xsHidden xs={24}>
            <br />
            <SelectPicker
              data={selectCerts}
              defaultValue={value.certificate}
              onChange={(certificate) => {
                onChange({...value, certificate})
              }}
              disabled={isDisabled}
              block
            />
          </Col>
        </Row>

        {hasBasicAuth && (
          <Row className="show-grid">
            <Col xsHidden xs={24}>
              <br />
              {t('Basic Auth')}
              <br />
              <br />
              <Toggle
                checked={value.basicAuth.active}
                onChange={(basicAuth) => {
                  onChange({...value, basicAuth: {...value.basicAuth, active: basicAuth}})
                }}
              />
              <br />
              <br />
              {value.basicAuth.active && (
                <div style={{display: 'flex'}}>
                  <Input
                    placeholder={t('tenantList.panels.user')}
                    value={value.basicAuth.user}
                    onChange={(basicAuthUser) => {
                      onChange({...value, basicAuth: {...value.basicAuth, user: basicAuthUser}})
                    }}
                  />
                  <Input
                    placeholder={t('tenantList.panels.password')}
                    value={value.basicAuth.pass}
                    type="password"
                    onChange={(basicAuthPass) => {
                      onChange({...value, basicAuth: {...value.basicAuth, pass: basicAuthPass}})
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        )}
      </Grid>
    )
  }

  return (
    <EditorTemplate
      navigationChildren={
        <NavigationBar
          leftChildren={
            <IconButtonLink
              size={'lg'}
              icon={<ArrowLeft />}
              route={TenantListRoute.create({})}
              disabled={isDisabled}
              // onClick={(e) => {
              //   // if (!unsavedChangesDialog()) e.preventDefault()
              // }}
            >
              {t('global.buttons.cancel')}
            </IconButtonLink>
          }
          rightChildren={
            <IconButton
              style={{
                marginLeft: '10px',
              }}
              size={'lg'}
              icon={<Save />}
              disabled={isDisabled}
              onClick={() => {
                setLoading(true)
                handleSave()
              }}
            >
              {t('global.buttons.save')}
            </IconButton>
          }
        />
      }
    >
      <div
        style={{
          width: '100%',
        }}
      >
        {loading && <OverlayLoader />}

        <h4>{pageTitle}</h4>
        <br />
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel>{t('tenantList.panels.name')}</Form.ControlLabel>
            <Form.Control
              name={t('tenantList.panels.name')}
              value={name}
              disabled={isDisabled || !!id}
              onChange={(value) => setName(slugifyString(value, false))}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>{t('tenantList.panels.active')}</Form.ControlLabel>
            <Toggle
              checked={active}
              disabled={isDisabled}
              onChange={(value) => {
                setActive(value)
              }}
            />
          </Form.Group>

          {showTenantSslCert && (
            <>
              <h6 className="wep-section-title">{t('tenantList.panels.sslCerts')}</h6>
              <Form.Group>
                <ListInput
                  value={certs}
                  onChange={(certs) => {
                    setCerts(certs)
                  }}
                  disabled={isDisabled}
                  defaultValue={{
                    id: '',
                    name: '',
                    publicKey: '',
                    privateKey: '',
                  }}
                >
                  {(props) => <CertEditPanel {...props} />}
                </ListInput>
              </Form.Group>
              <br />
            </>
          )}

          <h6 className="wep-section-title">{t('tenantList.panels.apiDomain')}</h6>
          <ListInput
            value={apiDomains}
            onChange={(domains) => setApiDomains(domains)}
            disabled={isDisabled}
            defaultValue={{
              protocol: 'https://',
              domain: '',
              certificate: 'letsencrypt',
              id: '',
              ingressName: '',
            }}
          >
            {({value, onChange}) => domainItem(value, onChange)}
          </ListInput>
          <br />

          <h6 className="wep-section-title">{t('tenantList.panels.editorDomain')}</h6>
          <ListInput
            value={editorDomains}
            onChange={(domains) => setEditorDomains(domains)}
            disabled={isDisabled}
            defaultValue={{
              protocol: 'https://',
              domain: '',
              certificate: 'letsencrypt',
              id: '',
              ingressName: '',
            }}
          >
            {({value, onChange}) => domainItem(value, onChange)}
          </ListInput>
          <br />

          <h6 className="wep-section-title">{t('tenantList.panels.domainListFrontend')}</h6>
          <ListInput
            value={frontendDomains}
            onChange={(frontendDomain) => setFrontendDomains(frontendDomain)}
            disabled={isDisabled}
            defaultValue={{
              id: '',
              ingressName: '',
              protocol: 'https://',
              domain: '',
              certificate: 'letsencrypt',
              basicAuth: {
                active: false,
                user: '',
                pass: '',
                secretName: '',
              },
            }}
          >
            {({value, onChange}) => domainItem(value, onChange, true)}
          </ListInput>
          <br />

          <h6 className="wep-section-title">{t('tenantList.panels.mediaDomainList')}</h6>
          <ListInput
            maxItems={1}
            value={mediaDomains}
            onChange={(domains) => setMediaDomains(domains)}
            disabled={isDisabled}
            defaultValue={{
              protocol: 'https://',
              domain: '',
              certificate: 'letsencrypt',
              id: '',
              ingressName: '',
            }}
          >
            {({value, onChange}) => domainItem(value, onChange)}
          </ListInput>
          <br />
        </Form>
      </div>
    </EditorTemplate>
  )
}
