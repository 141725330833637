import React, {useState, useContext, FormEvent, useEffect} from 'react'

import {LoginTemplate} from './atoms/loginTemplate'

import {useRouteDispatch, matchRoute, useRoute, IndexRoute, LoginRoute} from './route'
import {AuthDispatchContext, AuthDispatchActionType} from './authContext'

import {LocalStorageKey} from './utility'
import {Logo} from './logo'
import {
  useCreateSessionWithJwtMutation,
  useCreateSessionMutation,
  FullUserRoleFragment,
} from './api'

import {useTranslation} from 'react-i18next'
import {Button, Form, toaster, Message} from 'rsuite'
import {RouteActionType} from './router'

declare const PasswordCredential: any

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {current} = useRoute()

  const authDispatch = useContext(AuthDispatchContext)
  const routeDispatch = useRouteDispatch()

  const [authenticate, {loading, error: errorLogin}] = useCreateSessionMutation()

  const [authenticateWithJWT, {loading: loadingJWT, error: errorJWT}] =
    useCreateSessionWithJwtMutation()

  const {t} = useTranslation()

  useEffect(() => {
    if (current !== null && current.path === '/login/jwt' && current.query && current.query.jwt) {
      authenticateWithJWT({
        variables: {
          jwt: current.query.jwt,
        },
      })
        .then((response: any) => {
          const {
            token: sessionToken,
            user: {email: responseEmail, roles},
          } = response.data.createSessionWithJWT

          authenticateUser(sessionToken, responseEmail, roles)
        })
        .catch((error) => {
          console.warn('auth error', error)
          routeDispatch({type: RouteActionType.ReplaceRoute, route: LoginRoute.create({})})
        })
    }
  }, [current])

  useEffect(() => {
    const error = errorLogin?.message ?? errorJWT?.message

    if (error) {
      toaster.push(
        <Message closable type="error" duration={5000}>
          {error}
        </Message>
      )
    }
  }, [errorLogin, errorJWT])

  useEffect(() => {
    document.title = t('login.login')
  })

  async function login(e: FormEvent) {
    e.preventDefault()

    let response

    try {
      response = await authenticate({variables: {email, password}})
    } catch (error) {
      return
    }

    if (!response.data?.createSession) return

    const {
      token: sessionToken,
      user: {email: responseEmail, roles},
    } = response.data.createSession

    if (typeof PasswordCredential !== 'undefined') {
      const cred = new PasswordCredential({
        id: email,
        password,
      })
      await navigator.credentials.store(cred)
    }

    authenticateUser(sessionToken, responseEmail, roles)
  }

  function authenticateUser(
    sessionToken: string,
    responseEmail: string,
    userRoles: FullUserRoleFragment[]
  ) {
    const permissions = userRoles.reduce((permissions, userRole) => {
      return [...permissions, ...userRole.permissions.map((permission) => permission.id)]
    }, [] as string[])

    if (!permissions.includes('CAN_LOGIN_EDITOR')) {
      toaster.push(
        <Message closable type="error" duration={3000}>
          {t('login.unauthorized')}
        </Message>
      )

      return
    }

    localStorage.setItem(LocalStorageKey.SessionToken, sessionToken)

    authDispatch({
      type: AuthDispatchActionType.Login,
      email: responseEmail,
      sessionToken,
    })

    if (current!.query && current!.query.next) {
      const route = matchRoute(location.origin + current!.query.next)
      if (route) {
        routeDispatch({type: RouteActionType.ReplaceRoute, route})
        return
      }
    }
    routeDispatch({type: RouteActionType.ReplaceRoute, route: IndexRoute.create({})})
  }

  return (
    <LoginTemplate backgroundChildren={<Background />}>
      <>
        <Form
          fluid={true}
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
          }}
        >
          <Form.Group>
            <Form.ControlLabel>{t('login.email')}</Form.ControlLabel>
            <Form.Control
              name="email"
              value={email}
              autoComplete={'email'}
              onChange={(email) => setEmail(email)}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{t('login.password')}</Form.ControlLabel>
            <Form.Control
              name="password"
              type="password"
              value={password}
              autoComplete={'currentPassword'}
              onChange={(password) => setPassword(password)}
            />
          </Form.Group>
          <Button appearance="primary" type="submit" disabled={loading} onClick={login}>
            {t('login.login')}
          </Button>
        </Form>
      </>

      {loadingJWT && (
        <div>
          <p>{t('login.jwt')}</p>
        </div>
      )}
    </LoginTemplate>
  )
}

function Background() {
  return (
    <div
      style={{
        position: 'relative',
        width: 340,
        height: 40,
        transform: 'translateY(-0px)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '50%',
          width: 260,
          height: 260,
          borderRadius: '100%',
          transform: 'translateX(-50%) translateX(-180px) translateY(-40px)',
          background: 'linear-gradient(230deg, #F08C1F 0%, #FFA463 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: '50%',
          width: 260,
          height: 260,
          borderRadius: '100%',
          transform: 'translateX(-50%) translateX(180px) translateY(-40px)',
          background: 'linear-gradient(10deg, #29805A 0%, #34D690 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: '50%',
          width: 260,
          height: 260,
          borderRadius: '100%',
          transform: 'translateX(-50%) translateY(-140px)',
          background: 'linear-gradient(-40deg, #03738C 0%, #04C4D9 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: 340,
          height: 340,
          borderRadius: '100%',
          transform: 'translateY(-80px)',
          background: 'linear-gradient(-90deg, #D95560 0%, #FF6370 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 230,
        }}
      >
        <Logo />
      </div>
    </div>
  )
}
