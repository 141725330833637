import React, {ReactNode, useContext, useEffect} from 'react'
import {
  Container,
  Sidebar,
  Sidenav,
  Nav,
  Navbar,
  IconButton,
  Dropdown,
  CustomProvider,
} from 'rsuite'

import {
  useRoute,
  RouteType,
  routeLink,
  UserListRoute,
  UserRoleListRoute,
  LogoutRoute,
  TenantListRoute,
  CopyDataRoute,
} from './route'

import {useTranslation} from 'react-i18next'
import {AppContext} from './control/appContext'

import Peoples from '@rsuite/icons/legacy/Peoples'
import Cog from '@rsuite/icons/legacy/Cog'
import Globe from '@rsuite/icons/legacy/Globe'
import SignOut from '@rsuite/icons/legacy/SignOut'
import Task from '@rsuite/icons/legacy/Task'
import CloudReflashIcon from '@rsuite/icons/CloudReflash'

import en from 'rsuite/locales/en_US'
import {ClientSettings} from '../shared/types'
import {ElementID} from '../shared/elementID'
const de = require('./locales/rsuite_de.js')

export interface BaseProps {
  children?: ReactNode
}

export const AVAILABLE_LANG = [
  {id: 'en', lang: 'en_US', name: 'English'},
  {id: 'de', lang: 'de_CH', name: 'Deutsch'},
]

const headerStyles = {
  height: 60,
  paddingLeft: 10,
  display: 'flex',
  alignItems: 'center',
}

const settingsStyles = {
  marginLeft: 10,
}

const NavItemLink = routeLink(Nav.Item)

const DropdownItemLink = routeLink(Dropdown.Item)

export function Base({children}: BaseProps) {
  const {current} = useRoute()
  const {t, i18n} = useTranslation()
  const appContext = useContext(AppContext)
  const [appContextState, dispatchAppContext] = appContext

  useEffect(() => {
    i18n.changeLanguage(appContextState.uiLanguage)
  }, [appContextState.uiLanguage, i18n])

  const {showCopyDataMenu}: ClientSettings = JSON.parse(
    document.getElementById(ElementID.Settings)!.textContent!
  )

  return (
    <CustomProvider locale={appContextState.uiLanguage === 'de' ? de : en}>
      <div style={{display: 'flex', height: '100vh', width: '100vw'}}>
        <Container>
          <Sidebar style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
            <Sidenav defaultOpenKeys={['1']} style={{flex: '1 1 auto'}}>
              <Sidenav.Header>
                <div style={headerStyles}></div>
              </Sidenav.Header>
              <Sidenav.Body>
                <Nav>
                  <NavItemLink
                    icon={<Task />}
                    route={TenantListRoute.create({})}
                    active={current?.type === RouteType.TenantList}
                  >
                    {t('navbar.tenants')}
                  </NavItemLink>

                  {showCopyDataMenu && (
                    <NavItemLink
                      icon={<CloudReflashIcon />}
                      route={CopyDataRoute.create({})}
                      active={current?.type === RouteType.CopyData}
                    >
                      {t('navbar.copyData')}
                    </NavItemLink>
                  )}

                  <Dropdown
                    eventKey={'1'}
                    placement="rightStart"
                    title={t('navbar.usersAndMembers')}
                    icon={<Peoples />}
                  >
                    <DropdownItemLink
                      active={current?.type === RouteType.UserList}
                      route={UserListRoute.create({})}
                    >
                      {t('navbar.users')}
                    </DropdownItemLink>

                    <DropdownItemLink
                      active={current?.type === RouteType.UserRoleList}
                      route={UserRoleListRoute.create({})}
                    >
                      {t('navbar.userRoles')}
                    </DropdownItemLink>
                  </Dropdown>
                </Nav>
              </Sidenav.Body>
            </Sidenav>

            <Navbar className="settings">
              <Nav style={settingsStyles}>
                <Dropdown
                  placement="topStart"
                  renderToggle={(props, ref) => (
                    <IconButton {...props} ref={ref} icon={<Cog />} circle appearance="ghost" />
                  )}
                >
                  <Dropdown.Menu icon={<Globe />} title={t('navbar.language')}>
                    {AVAILABLE_LANG.map((lang) => (
                      <Dropdown.Item
                        key={lang.id}
                        onSelect={() => dispatchAppContext({type: 'language', uiLanguage: lang.id})}
                        active={lang.id === appContextState.uiLanguage}
                      >
                        {lang.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                  <DropdownItemLink route={LogoutRoute.create({})} icon={<SignOut />}>
                    {t('navbar.logout')}
                  </DropdownItemLink>
                </Dropdown>
              </Nav>
            </Navbar>
          </Sidebar>
          <Container
            style={{
              paddingTop: '60px',
              paddingBottom: '0px',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            {children}
          </Container>
        </Container>
      </div>
    </CustomProvider>
  )
}
