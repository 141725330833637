import React from 'react'
import {SortableHandle, SortableContainer, SortableElement} from 'react-sortable-hoc'
import {nanoid} from 'nanoid'
import {IconButton, Panel, Divider} from 'rsuite'
import {isFunctionalUpdate} from '../utility'
import arrayMove from 'array-move' // TODO: npm update to 4.0.0

import Th2 from '@rsuite/icons/legacy/Th2'
import Trash from '@rsuite/icons/legacy/Trash'
import Plus from '@rsuite/icons/legacy/Plus'

export interface FieldProps<V = any> {
  readonly value: V
  readonly onChange: React.Dispatch<React.SetStateAction<V>>
}

export interface ListFieldProps<T = any> extends FieldProps<ListValue<T>[]> {
  readonly maxItems?: number
  readonly label?: string
  readonly defaultValue: T | (() => T)
  readonly disabled?: boolean
  readonly disableAddItem?: boolean
  readonly children: (props: FieldProps<T>) => JSX.Element
}

export interface ListValue<T = any> {
  readonly id: string
  readonly value: T
}

export interface ListItemProps<T = any> {
  readonly value: ListValue<T>
  readonly itemIndex: number
  readonly itemDisabled?: boolean
  readonly onChange: (index: number, value: React.SetStateAction<ListValue<T>>) => void
  readonly onRemove: (index: number) => void
  readonly children: (props: FieldProps<T>) => JSX.Element
}

const DragHandle = SortableHandle(({disabled}: {disabled?: boolean}) => (
  <IconButton size="sm" icon={<Th2 />} disabled={disabled} />
))

const ListItem = SortableElement(
  ({value, itemIndex, itemDisabled, onChange, onRemove, children}: ListItemProps) => {
    function handleValueChange(fieldValue: React.SetStateAction<any>) {
      onChange(itemIndex, (value) => ({
        ...value,
        value: isFunctionalUpdate(fieldValue) ? fieldValue(value.value) : fieldValue,
      }))
    }

    function handleRemove() {
      onRemove(itemIndex)
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '20px',
        }}
      >
        <Panel bordered bodyFill style={{width: '100%', padding: '20px'}}>
          <div style={{minHeight: '100%'}}>
            {children({value: value.value, onChange: handleValueChange})}
          </div>
        </Panel>
        <div style={{marginLeft: '10px'}}>
          <DragHandle disabled={itemDisabled} />
          <Divider style={{margin: '12px 0'}}></Divider>
          <IconButton size="sm" icon={<Trash />} onClick={handleRemove} disabled={itemDisabled} />
        </div>
      </div>
    )
  }
)

const SortableList = SortableContainer(
  ({value, defaultValue, disabled, children, onChange, disableAddItem}: ListFieldProps) => {
    function handleItemChange(itemIndex: number, itemValue: React.SetStateAction<ListValue>) {
      onChange((value) =>
        Object.assign([], value, {
          [itemIndex]: isFunctionalUpdate(itemValue) ? itemValue(value[itemIndex]) : itemValue,
        })
      )
    }

    function handleAdd() {
      onChange((value) => [...value, {id: nanoid(), value: defaultValue}])
    }

    function handleRemove(itemIndex: number) {
      onChange((value) => value.filter((_, index) => index !== itemIndex))
    }

    return (
      <div>
        {value.map((value: any, index: number) => (
          <ListItem
            key={value.id}
            itemIndex={index}
            index={index}
            value={value}
            itemDisabled={disabled}
            onChange={handleItemChange}
            onRemove={handleRemove}
          >
            {children}
          </ListItem>
        ))}

        <Divider>
          <IconButton
            size="md"
            appearance="ghost"
            circle
            icon={<Plus />}
            onClick={handleAdd}
            disabled={disabled || disableAddItem}
          />
        </Divider>
      </div>
    )
  }
)

export function ListInput<T>({
  maxItems,
  value,
  label,
  defaultValue,
  disabled,
  children,
  onChange,
}: ListFieldProps<T>) {
  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
    onChange(arrayMove(value, oldIndex, newIndex))
  }

  let disabledAddItem = disabled
  if (maxItems && value.length >= maxItems) {
    disabledAddItem = true
  }

  return (
    <div>
      {label && <label>{label}</label>}
      <SortableList
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        disableAddItem={disabledAddItem}
        children={children} // eslint-disable-line react/no-children-prop
        onChange={onChange}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  )
}
