import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import {ApolloProvider, ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client'
import {createUploadLink} from 'apollo-upload-client'
import {onError} from '@apollo/client/link/error'

import './i18n'

import {ElementID} from '../shared/elementID'
import {ClientSettings} from '../shared/types'
import {RouteProvider} from './route'
import {AuthProvider} from './authContext'
import {LocalStorageKey} from './utility'
import {HotApp} from './app'

// See: https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
export async function fetchIntrospectionQueryResultData(url: string) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      variables: {},
      query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
    }),
  })

  const result = await response.json()

  const possibleTypes: any = {}

  result.data.__schema.types.forEach((supertype: any) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype: any) => subtype.name)
    }
  })

  return possibleTypes
}

export const initClient = async () => {
  const {apiURL}: ClientSettings = JSON.parse(
    document.getElementById(ElementID.Settings)!.textContent!
  )

  const adminAPIURL = `${apiURL}/admin`

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(LocalStorageKey.SessionToken)
    const context = operation.getContext()

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        ...context.headers,
      },
      ...context,
    })

    return forward(operation)
  })

  const authErrorLink = onError(({graphQLErrors, /* networkError, */ operation, forward}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({/* message, locations, path, */ extensions}) => {
        if (extensions?.code === 'UNAUTHENTICATED') {
          window.location.pathname = '/logout'
        }
      })
    }
    return forward(operation)
  })

  const client = new ApolloClient({
    link: authLink
      .concat(authErrorLink)
      .concat(createUploadLink({uri: adminAPIURL, headers: {'Apollo-Require-Preflight': 'true'}})),
    cache: new InMemoryCache({
      possibleTypes: await fetchIntrospectionQueryResultData(adminAPIURL),
    }),
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  })

  window.addEventListener('dragover', (e) => e.preventDefault())
  window.addEventListener('drop', (e) => e.preventDefault())

  ReactDOM.render(
    <ApolloProvider client={client}>
      <AuthProvider>
        <RouteProvider>
          <HotApp />
        </RouteProvider>
      </AuthProvider>
    </ApolloProvider>,
    document.getElementById(ElementID.ReactRoot)
  )
}
